<template>
    <v-dialog v-model="dialog" max-width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                elevation="0"
                outlined
                small
                :disabled="loggedInUser"
                v-bind="attrs"
                v-on="on"
                >Modify</v-btn
            >
        </template>
        <div
            class="container-bg"
            style="
                margin: auto;
                display: block;
                background: rgba(20, 20, 20, 0.8);
            "
        >
            <div class="container-fg">
                <v-card
                    dark
                    style="background-color: transparent"
                    elevation="0"
                >
                    <v-card-title
                        style="
                            background-color: transparent;
                            justify-content: space-between;
                        "
                    >
                        <h1
                            v-if="currentAccount.account"
                            style="
                                font-size: 36px;
                                letter-spacing: -0.08rem;
                                color: white;
                                margin-left: -5px;
                            "
                        >
                            {{ emailName(currentAccount.account.email) }}
                        </h1>
                        <br />
                        <h1
                            v-if="currentAccount.account"
                            style="
                                font-size: 24px;
                                letter-spacing: -0.04rem;
                                color: rgb(165, 165, 165);
                                margin-left: -5px;
                            "
                        >
                            @{{ emailEnd(currentAccount.account.email) }}
                        </h1>
                        <v-chip
                            v-if="currentAccount.admin === true"
                            class="ma-2"
                            color="orange"
                            label
                            small
                        >
                            Admin
                            <v-icon>{{ mdiAccountSupervisor }}</v-icon>
                        </v-chip>
                    </v-card-title>
                    <v-divider color="gray"></v-divider>
                    <div
                        style="
                            display: flex;
                            flex-direction: column;
                            padding-left: -15px;
                            padding-right: 15px;
                        "
                    >
                        <!-- <v-checkbox
                            v-model="viewBranchRma"
                            label="View Branch RMA"
                        ></v-checkbox> -->
                        <!-- <v-checkbox
                            v-model="viewSubBranchRma"
                            style="margin-top: -10px !important"
                            label="View Sub-Branch RMA"
                        ></v-checkbox> -->
                        <v-checkbox
                            v-model="adminRole"
                            label="Admin"
                        ></v-checkbox>
                        <base-btn
                            class="elevation-0 dialog-btns"
                            outlined
                            @click="confirmDialog = true"
                        >
                            Remove Account
                        </base-btn>
                        <base-btn
                            class="dialog-btns"
                            color="primary"
                            elevation="0"
                            @click="updateUser"
                        >
                            Save
                        </base-btn>
                        <base-btn
                            class="dialog-btns"
                            color="primary"
                            outlined
                            @click="dialog = false"
                        >
                            Close
                        </base-btn>
                    </div>
                </v-card>
            </div>
        </div>
        <v-dialog
            v-if="currentAccount.account"
            v-model="confirmDialog"
            width="700"
        >
            <div class="container-bg">
                <div class="container-fg">
                    <v-card
                        style="background-color: transparent"
                        elevation="0"
                        dark
                    >
                        <v-card-title dark>
                            <h1
                                style="
                                    font-size: 36px;
                                    letter-spacing: -0.08rem;
                                    color: white;
                                "
                            >
                                Are you sure you want to remove <br />
                                {{ currentAccount.account.email }}?
                            </h1>
                        </v-card-title>

                        <v-divider></v-divider>

                        <v-card-text>
                            You will not be able to undo this action. <br />
                            To confirm, please type in the email address "{{
                                currentAccount.account.email
                            }}" in the box below. <br />
                            <v-text-field
                                v-model="confirmDelete"
                                style="margin-top: 15px; margin-bottom: -15px"
                                outlined
                                label="Email"
                            ></v-text-field>
                        </v-card-text>

                        <div>
                            <base-btn
                                color="primary"
                                outlined
                                @click="dialog = false"
                            >
                                Cancel
                            </base-btn>
                            <base-btn
                                class="elevation-0 dialog-btns"
                                style="float: right"
                                :disabled="confirmDeletion"
                                @click="deleteUser"
                            >
                                Remove {{ currentAccount.account.email }}
                            </base-btn>
                        </div>
                    </v-card>
                </div>
            </div>
        </v-dialog>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="white"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import { mdiAccountSupervisor } from '@mdi/js'
import gql from 'graphql-tag'

export default {
    name: 'UpdateUser',
    props: {
        currentLevel: {
            type: Object,
            required: true
        },
        currentAccount: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            confirmDialog: false,
            confirmDelete: '',
            orgName: '',
            snackbar: false,
            snackbarMessage: '',
            accountEmail: '',
            mdiAccountSupervisor,
            local: null
        }
    },
    computed: {
        loggedInUser() {
            return this.$auth.user.id === this.currentAccount.accountId
        },
        confirmDeletion() {
            if (this.confirmDelete === this.currentAccount.account.email) {
                return false
            } else {
                return true
            }
        }
    },
    watch: {
        currentAccount: {
            immediate: true,
            handler() {
                this.local = { ...this.currentAccount }
                // this.viewBranchRma =
                //     this.local.options !== null
                //         ? this.local.options.viewBranchRma
                //         : false
                // this.viewSubBranchRma =
                //     this.local.options !== null
                //         ? this.local.options.viewSubBranchRma
                //         : false
                this.adminRole = this.local.admin
            }
        }
    },
    methods: {
        emailName(email) {
            const name = email.split('@')
            return name[0]
        },
        emailEnd(email) {
            const name = email.split('@')
            return name[1]
        },
        updateUser() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateUserOfOrganization(
                            $admin: Boolean!
                            $userId: String!
                            $orgId: String!
                        ) {
                            updateUserOfOrganization(
                                admin: $admin
                                userId: $userId
                                orgId: $orgId
                            ) {
                                complete
                                error
                                success
                                uid
                            }
                        }
                    `,
                    variables: {
                        admin: this.adminRole,
                        orgId: this.currentLevel.baseAccountId,
                        userId: this.currentAccount.accountId
                    }
                })
                .then((data) => {
                    if (data.data.updateUserOfOrganization.success === true) {
                        this.$emit(
                            'updated',
                            `${this.currentAccount.account.email}' successfully updated`
                        )
                        this.dialog = false
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error updating '${this.currentAccount.account.email}'`
                    }
                })
        },
        deleteUser() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation RemoveUserFromOrganization(
                            $orgId: String!
                            $email: String
                            $userId: String
                        ) {
                            removeUserFromOrganization(
                                orgId: $orgId
                                email: $email
                                userId: $userId
                            ) {
                                complete
                                error
                                success
                                uid
                            }
                        }
                    `,
                    variables: {
                        orgId: this.currentLevel.baseAccountId,
                        email: this.currentAccount.account.email,
                        userId: this.currentAccount.accountId
                    }
                })
                .then((data) => {
                    if (data.data.removeUserFromOrganization.success === true) {
                        this.$emit(
                            'updated',
                            `'${this.currentAccount.account.email}' successfully deleted`
                        )
                        this.dialog = false
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error deleting '${this.currentAccount.account.email}'`
                    }
                })
        }
    }
}
</script>

<style scoped>
.dialog-btns {
    margin-bottom: 15px;
}
</style>
