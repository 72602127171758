<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="tableView"
                color="primary"
                elevation="0"
                outlined
                width="80px"
                small
                v-bind="attrs"
                v-on="on"
                >Remove</v-btn
            >
            <v-btn
                v-if="!tableView"
                color="primary"
                outlined
                elevation="0"
                v-bind="attrs"
                style="display: block; margin: auto"
                v-on="on"
            >
                Remove User
            </v-btn>
        </template>
        <div
            class="container-bg"
            style="
                margin: auto;
                display: block;
                background: rgba(20, 20, 20, 0.8);
            "
        >
            <div class="container-fg">
                <v-card
                    style="background-color: transparent"
                    elevation="0"
                    dark
                >
                    <v-card-title dark>
                        <h1
                            style="
                                font-size: 36px;
                                letter-spacing: -0.08rem;
                                color: white;
                            "
                        >
                            Are you sure you want to remove
                            <br />
                            {{ currentAccount.requestedEmail }}?
                        </h1>
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text>
                        This user has not yet accepted the organization invite.
                        <br />
                        To remove this user, please type in the email address
                        "{{ currentAccount.requestedEmail }}" in the box below.
                        <br />
                        <v-text-field
                            v-model="confirmDelete"
                            style="margin-top: 15px; margin-bottom: -15px"
                            outlined
                            label="Email"
                        ></v-text-field>
                    </v-card-text>

                    <div>
                        <base-btn
                            color="primary"
                            outlined
                            @click="dialog = false"
                        >
                            Cancel
                        </base-btn>
                        <base-btn
                            class="elevation-0 dialog-btns"
                            style="float: right"
                            :disabled="confirmDeletion"
                            @click="deleteUser"
                        >
                            Remove {{ currentAccount.requestedEmail }}
                        </base-btn>
                    </div>
                </v-card>
            </div>
        </div>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="white"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import { mdiAccountSupervisor } from '@mdi/js'
import gql from 'graphql-tag'

export default {
    name: 'UpdateUser',
    props: {
        currentLevel: {
            type: Object,
            required: true
        },
        currentAccount: {
            type: Object,
            required: true
        },
        tableView: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            confirmDialog: false,
            confirmDelete: '',
            orgName: '',
            snackbar: false,
            snackbarMessage: '',
            isAdmin: this.currentAccount.admin,
            accountEmail: '',
            submitted: false,
            mdiAccountSupervisor
        }
    },
    computed: {
        confirmDeletion() {
            if (this.confirmDelete === this.currentAccount.requestedEmail) {
                return false
            } else {
                return true
            }
        }
    },
    methods: {
        deleteUser() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation RemoveUserFromOrganization(
                            $orgId: String!
                            $email: String
                            $userId: String
                        ) {
                            removeUserFromOrganization(
                                orgId: $orgId
                                email: $email
                                userId: $userId
                            ) {
                                complete
                                error
                                success
                                uid
                            }
                        }
                    `,
                    variables: {
                        orgId: this.currentLevel.baseAccountId,
                        email: this.currentAccount.requestedEmail,
                        userId: this.currentAccount.accountId
                    }
                })
                .then((data) => {
                    if (data.data.removeUserFromOrganization.success === true) {
                        this.$emit(
                            'deleted',
                            `'${this.currentAccount.requestedEmail}' successfully deleted`
                        )
                        this.confirmDelete = ''
                        this.dialog = false
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error deleting '${this.currentAccount.requestedEmail}'`
                    }
                })
        }
    }
}
</script>

<style scoped>
.dialog-btns {
    margin-bottom: 15px;
}
</style>
